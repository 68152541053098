import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <Link to={"study"}>Studys</Link>
      <Link to={"takeover_news"}>Takeover</Link>
      <Link to={"resource"}>Resources</Link>
    </>
  );
}

export default Home;
