import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import API from "../../utils/API";
import { Link } from "react-router-dom";

import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import FormStudy from "../studys/form";

import { useForm, useFieldArray, Controller } from "react-hook-form";

function TakeoverNewsDocuments() {
  const [study_documents, set_study_documents] = useState(null);
  const [document_count, set_document_count] = useState(null);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [selected_study, set_selected_study] = useState(null);

  useEffect(() => {
    GetStudyDocuments();
  }, [pageNumber]);

  async function GetStudyDocuments() {
    API.get("/documents/takeover_news", { params: { page: pageNumber, limit: 30 } })
      .then((r) => {
        set_study_documents(r.data.data);
        setNumPages(r.data.pages);
        set_document_count(r.data.results);
        set_selected_study(0);
      })
      .catch((e) => console.log(e));
  }

  const SelectItem = (i) => {
    set_selected_study(i);
  };

  const toolbarPluginInstance = toolbarPlugin({
    searchPlugin: {
      keyword: "PDF",
    },
    // selectionModePlugin: {
    //   selectionMode: SelectionMode.Hand,
    // },
  });
  const { Toolbar } = toolbarPluginInstance;

  return (
    <div className="main">
      <div className="content">
        <div className="document_viwer">
          <ul className="tight-list">
            {study_documents
              ? study_documents.map((item, i) => (
                  <li key={item.id} onClick={(e) => SelectItem(i)}>
                    <span className="item_id">#{item.id}</span>
                    <span>{item.date && format(parseISO(item.date), "dd MMM Y")}</span>
                  </li>
                ))
              : null}
            <div className="pagination-row">
              <span
                className={pageNumber == 0 ? "pg-disabled" : undefined}
                onClick={pageNumber == 0 ? undefined : () => setPageNumber(pageNumber - 1)}
              >
                Prev
              </span>
              <span>
                {pageNumber}/{numPages}
              </span>
              <span
                className={pageNumber == numPages ? "pg-disabled" : undefined}
                onClick={pageNumber == numPages ? undefined : () => setPageNumber(pageNumber + 1)}
              >
                Next
              </span>
            </div>
          </ul>

          <div className="doc_panel">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
              <div
                style={{
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  height: "calc(100vh - 200px)",
                }}
              >
                <Toolbar />
                {study_documents && (
                  <Viewer
                    defaultScale={1.25}
                    fileUrl={study_documents[selected_study].file_url}
                    // viewMode="DualPage"
                    plugins={[toolbarPluginInstance]}
                  />
                )}
              </div>
            </Worker>
          </div>
        </div>
      </div>
    </div>
  );
}

{
}

export default TakeoverNewsDocuments;
