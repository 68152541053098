import { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Select from "react-select";
import API from "../../utils/API";

import ReactSelect from "react-select";
import AsyncSelect from "react-select/async";

function FormStudy() {
  const is_add_mode = true;
  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    name: "commodities_produced",
    control,
  });
  const commodities_produced = watch("commodities_produced");

  useEffect(() => {
    if (!is_add_mode) {
    } else {
      reset({
        name: "rupice",
        is_greenfield: true,
        commodities_produced: [],
      });
    }
  }, [reset, is_add_mode]);

  const onSubmit = (data) => {
    if (!is_add_mode) {
      console.log(data);
    } else {
      data.tax_applied == 0 ? (data.tax_applied = false) : (data.tax_applied = true);
      data.commodities_produced &&
        data.commodities_produced.map(
          (cp) => (
            (cp.commodity = cp.value),
            (cp.grade_unit = cp.grade_unit || 1),
            (cp.quantity_pa_unit = cp.quantity_pa_unit || 1)
          )
        );
      data.project_id = data.project_id.value;
      console.log(data);
      API.post("/studys", data).catch((e) => console.log(e.response.data.errors));
    }
  };

  const filterProjects = (inputValue) =>
    API.get(`/get_select_options`, { params: { option: "PROJECT_LIST", search_string: inputValue } }).then((r) => {
      return r.data;
    });

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterProjects(inputValue));
    });

  const commodity_options = [
    { value: 1, label: "silver" },
    { value: 2, label: "gold" },
    { value: 3, label: "lead" },
    { value: 4, label: "zinc" },
    { value: 5, label: "copper" },
    { value: 6, label: "antimony" },
    { value: 7, label: "nickel" },
    { value: 8, label: "cobalt" },
    { value: 9, label: "barium sulfate" },
    { value: 10, label: "tin" },
    { value: 11, label: "molybdenum" },
    { value: 12, label: "uranium" },
    { value: 13, label: "lithium" },
    { value: 14, label: "vandium (V2O5)" },
    { value: 15, label: "phosphate" },
    { value: 16, label: "silica sand" },
    { value: 17, label: "potash" },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register("id", { valueAsNumber: true })} />
      <table className="edit">
        <tbody>
          <tr>
            <th>Project:</th>
            <td className="full-content" colspan="3">
              {/* <input type="text" {...register("project_id", { valueAsNumber: true })} /> */}
              <Controller
                name="project_id"
                control={control}
                render={({ field }) => (
                  <AsyncSelect
                    {...field}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isClearable
                    cacheOptions
                    loadOptions={promiseOptions}
                  />
                )}
              />
            </td>
          </tr>
          <tr>
            <th>Publish Date:</th>
            <td>
              <input type="date" {...register("effective_date")} />
            </td>
            <th>Study Level:</th>
            <td>
              <select type="text" {...register("study_level", { valueAsNumber: true })}>
                <option value={1}>SS</option>
                <option value={2}>PFS</option>
                <option value={3}>DFS</option>
                <option value={4}>BFS</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>Commodities Produced:</th>
            <td className="full-content" colspan="3">
              <Controller
                name="commodities_produced"
                // rules={{ required: true }}

                control={control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={commodity_options}
                    onChange={onChange}
                    isMulti={true}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    ref={ref}
                  />
                )}
              />
            </td>
          </tr>

          <tr>
            <th>Mine Type:</th>
            <td>
              <select type="text" {...register("mine_type", { valueAsNumber: true })}>
                <option value={1}>Open Pit</option>
                <option value={2}>Open Pit + Underground</option>
                <option value={3}>Underground</option>
              </select>
            </td>
            <th>LoM Years:</th>
            <td>
              <input
                type="text"
                {...register("mine_life", {
                  setValueAs: (v) => (v === "" ? null : parseFloat(v)),
                })}
              />
            </td>
          </tr>

          <tr>
            <th>Greenfields:</th>
            <td>
              <input type="checkbox" {...register("is_greenfield")} />
            </td>
            <th>Mill TPD:</th>
            <td>
              <input type="text" {...register("mine_tons_per_day", { valueAsNumber: true })} />
            </td>
          </tr>

          <tr>
            <th>Local Currency:</th>
            <td>
              <select type="text" {...register("local_currency")}>
                <option>USD</option>
                <option>CAD</option>
                <option>AUD</option>
                <option>EUR</option>
              </select>
            </td>
            <th>Initial Capital:</th>
            <td>
              <input
                type="text"
                {...register("initial_capital", {
                  setValueAs: (v) => (v === "" ? null : parseFloat(v)),
                })}
              />
            </td>
          </tr>

          <tr>
            <th>Total Capital (LOM)</th>
            <td>
              <input
                type="text"
                {...register("total_capital", {
                  setValueAs: (v) => (v === "" ? null : parseFloat(v)),
                })}
              />
            </td>
            <th> Discount Rate (%):</th>
            <td>
              <input
                type="text"
                {...register("discount_rate", {
                  setValueAs: (v) => (v === "" ? null : parseFloat(v)),
                })}
              />
            </td>
          </tr>
          <tr>
            <th>Mining Cost ($/t)</th>
            <td>
              <input
                type="text"
                {...register("mining_cost_per_t", {
                  setValueAs: (v) => (v === "" ? null : parseFloat(v)),
                })}
              />
            </td>
            <th>Processing Cost ($/t):</th>
            <td>
              <input
                type="text"
                {...register("processing_cost_per_t", {
                  setValueAs: (v) => (v === "" ? null : parseFloat(v)),
                })}
              />
            </td>
          </tr>
          <tr>
            <th>G&A Cost ($/t)</th>
            <td>
              <input
                type="text"
                {...register("gna_cost_per_t", {
                  setValueAs: (v) => (v === "" ? null : parseFloat(v)),
                })}
              />
            </td>
            <th>Tax Used:</th>
            <td>
              <select type="text" {...register("tax_applied", { valueAsNumber: true })}>
                <option value={1}>After-tax</option>
                <option value={0}>Pre-tax</option>
              </select>
            </td>
          </tr>

          <tr>
            <th>NPV ($M):</th>
            <td>
              <input
                type="text"
                {...register("npv", {
                  setValueAs: (v) => (v === "" ? null : parseFloat(v)),
                })}
              />
            </td>
            <th>IRR (%):</th>
            <td>
              <input
                type="text"
                {...register("irr", {
                  setValueAs: (v) => (v === "" ? null : parseFloat(v)),
                })}
              />
            </td>
          </tr>
          <tr>
            <th> Payback Period ( Months ):</th>
            <td>
              <input
                type="text"
                {...register("payback_period", {
                  setValueAs: (v) => (v === "" ? null : parseFloat(v)),
                })}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <table className="inner-table">
        <thead>
          <tr>
            <th>Commodity</th>
            <th>Primary</th>
            <th>Qty Pa</th>
            <th>Unit</th>
            <th>Price</th>
            <th>Grade</th>
            <th>Unit</th>
          </tr>
        </thead>

        <tbody>
          {commodities_produced &&
            commodities_produced.map((cp, index) => (
              <tr key={cp.value}>
                <td>{cp.label}</td>
                <td>
                  <input type="checkbox" {...register(`commodities_produced.${index}.is_primary`)} />
                </td>
                <td>
                  <input
                    type="text"
                    {...register(`commodities_produced.${index}.quantity_pa`, {
                      setValueAs: (v) => (v === "" ? null : parseFloat(v)),
                    })}
                  />
                </td>
                <td>
                  <select
                    defaultValue={1}
                    {...register(`commodities_produced.${index}.quantity_pa_unit`, { valueAsNumber: true })}
                  >
                    <option value={1}>koz</option>
                    <option value={2}>kt</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    {...register(`commodities_produced.${index}.price_used`, {
                      setValueAs: (v) => (v === "" ? null : parseFloat(v)),
                    })}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    {...register(`commodities_produced.${index}.grade`, {
                      setValueAs: (v) => (v === "" ? null : parseFloat(v)),
                    })}
                  />
                </td>
                <td>
                  <select
                    defaultValue={1}
                    {...register(`commodities_produced.${index}.grade_unit`, { valueAsNumber: true })}
                  >
                    <option value={1}>%</option>
                    <option value={2}>g/t</option>
                    <option value={3}>ppm</option>
                  </select>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <br />
      <input type="submit" disabled={isSubmitting} value={is_add_mode ? "Add Resource" : "Save Changes"} />
    </form>
  );
}

export default FormStudy;
