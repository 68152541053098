export const country_list = [
  {
    value: 1,
    iso: "AF",
    label: "AFGHANISTAN",
    nicename: "Afghanistan",
    iso3: "AFG",
    numcode: 4,
    phonecode: 93,
  },
  {
    value: 2,
    iso: "AL",
    label: "ALBANIA",
    nicename: "Albania",
    iso3: "ALB",
    numcode: 8,
    phonecode: 355,
  },
  {
    value: 3,
    iso: "DZ",
    label: "ALGERIA",
    nicename: "Algeria",
    iso3: "DZA",
    numcode: 12,
    phonecode: 213,
  },
  {
    value: 4,
    iso: "AS",
    label: "AMERICAN SAMOA",
    nicename: "American Samoa",
    iso3: "ASM",
    numcode: 16,
    phonecode: 1684,
  },
  {
    value: 5,
    iso: "AD",
    label: "ANDORRA",
    nicename: "Andorra",
    iso3: "AND",
    numcode: 20,
    phonecode: 376,
  },
  {
    value: 6,
    iso: "AO",
    label: "ANGOLA",
    nicename: "Angola",
    iso3: "AGO",
    numcode: 24,
    phonecode: 244,
  },
  {
    value: 7,
    iso: "AI",
    label: "ANGUILLA",
    nicename: "Anguilla",
    iso3: "AIA",
    numcode: 660,
    phonecode: 1264,
  },
  {
    value: 8,
    iso: "AQ",
    label: "ANTARCTICA",
    nicename: "Antarctica",
    iso3: "ATA",
    numcode: 10,
    phonecode: 0,
  },
  {
    value: 9,
    iso: "AG",
    label: "ANTIGUA AND BARBUDA",
    nicename: "Antigua and Barbuda",
    iso3: "ATG",
    numcode: 28,
    phonecode: 1268,
  },
  {
    value: 10,
    iso: "AR",
    label: "ARGENTINA",
    nicename: "Argentina",
    iso3: "ARG",
    numcode: 32,
    phonecode: 54,
  },
  {
    value: 11,
    iso: "AM",
    label: "ARMENIA",
    nicename: "Armenia",
    iso3: "ARM",
    numcode: 51,
    phonecode: 374,
  },
  {
    value: 12,
    iso: "AW",
    label: "ARUBA",
    nicename: "Aruba",
    iso3: "ABW",
    numcode: 533,
    phonecode: 297,
  },
  {
    value: 13,
    iso: "AU",
    label: "AUSTRALIA",
    nicename: "Australia",
    iso3: "AUS",
    numcode: 36,
    phonecode: 61,
  },
  {
    value: 14,
    iso: "AT",
    label: "AUSTRIA",
    nicename: "Austria",
    iso3: "AUT",
    numcode: 40,
    phonecode: 43,
  },
  {
    value: 15,
    iso: "AZ",
    label: "AZERBAIJAN",
    nicename: "Azerbaijan",
    iso3: "AZE",
    numcode: 31,
    phonecode: 994,
  },
  {
    value: 16,
    iso: "BS",
    label: "BAHAMAS",
    nicename: "Bahamas",
    iso3: "BHS",
    numcode: 44,
    phonecode: 1242,
  },
  {
    value: 17,
    iso: "BH",
    label: "BAHRAIN",
    nicename: "Bahrain",
    iso3: "BHR",
    numcode: 48,
    phonecode: 973,
  },
  {
    value: 18,
    iso: "BD",
    label: "BANGLADESH",
    nicename: "Bangladesh",
    iso3: "BGD",
    numcode: 50,
    phonecode: 880,
  },
  {
    value: 19,
    iso: "BB",
    label: "BARBADOS",
    nicename: "Barbados",
    iso3: "BRB",
    numcode: 52,
    phonecode: 1246,
  },
  {
    value: 20,
    iso: "BY",
    label: "BELARUS",
    nicename: "Belarus",
    iso3: "BLR",
    numcode: 112,
    phonecode: 375,
  },
  {
    value: 21,
    iso: "BE",
    label: "BELGIUM",
    nicename: "Belgium",
    iso3: "BEL",
    numcode: 56,
    phonecode: 32,
  },
  {
    value: 22,
    iso: "BZ",
    label: "BELIZE",
    nicename: "Belize",
    iso3: "BLZ",
    numcode: 84,
    phonecode: 501,
  },
  {
    value: 23,
    iso: "BJ",
    label: "BENIN",
    nicename: "Benin",
    iso3: "BEN",
    numcode: 204,
    phonecode: 229,
  },
  {
    value: 24,
    iso: "BM",
    label: "BERMUDA",
    nicename: "Bermuda",
    iso3: "BMU",
    numcode: 60,
    phonecode: 1441,
  },
  {
    value: 25,
    iso: "BT",
    label: "BHUTAN",
    nicename: "Bhutan",
    iso3: "BTN",
    numcode: 64,
    phonecode: 975,
  },
  {
    value: 26,
    iso: "BO",
    label: "BOLIVIA",
    nicename: "Bolivia",
    iso3: "BOL",
    numcode: 68,
    phonecode: 591,
  },
  {
    value: 27,
    iso: "BA",
    label: "BOSNIA AND HERZEGOVINA",
    nicename: "Bosnia and Herzegovina",
    iso3: "BIH",
    numcode: 70,
    phonecode: 387,
  },
  {
    value: 28,
    iso: "BW",
    label: "BOTSWANA",
    nicename: "Botswana",
    iso3: "BWA",
    numcode: 72,
    phonecode: 267,
  },
  {
    value: 29,
    iso: "BV",
    label: "BOUVET ISLAND",
    nicename: "Bouvet Island",
    iso3: "BVT",
    numcode: 74,
    phonecode: 0,
  },
  {
    value: 30,
    iso: "BR",
    label: "BRAZIL",
    nicename: "Brazil",
    iso3: "BRA",
    numcode: 76,
    phonecode: 55,
  },
  {
    value: 31,
    iso: "IO",
    label: "BRITISH INDIAN OCEAN TERRITORY",
    nicename: "British Indian Ocean Territory",
    iso3: "IOT",
    numcode: 86,
    phonecode: 246,
  },
  {
    value: 32,
    iso: "BN",
    label: "BRUNEI DARUSSALAM",
    nicename: "Brunei Darussalam",
    iso3: "BRN",
    numcode: 96,
    phonecode: 673,
  },
  {
    value: 33,
    iso: "BG",
    label: "BULGARIA",
    nicename: "Bulgaria",
    iso3: "BGR",
    numcode: 100,
    phonecode: 359,
  },
  {
    value: 34,
    iso: "BF",
    label: "BURKINA FASO",
    nicename: "Burkina Faso",
    iso3: "BFA",
    numcode: 854,
    phonecode: 226,
  },
  {
    value: 35,
    iso: "BI",
    label: "BURUNDI",
    nicename: "Burundi",
    iso3: "BDI",
    numcode: 108,
    phonecode: 257,
  },
  {
    value: 36,
    iso: "KH",
    label: "CAMBODIA",
    nicename: "Cambodia",
    iso3: "KHM",
    numcode: 116,
    phonecode: 855,
  },
  {
    value: 37,
    iso: "CM",
    label: "CAMEROON",
    nicename: "Cameroon",
    iso3: "CMR",
    numcode: 120,
    phonecode: 237,
  },
  {
    value: 38,
    iso: "CA",
    label: "CANADA",
    nicename: "Canada",
    iso3: "CAN",
    numcode: 124,
    phonecode: 1,
  },
  {
    value: 39,
    iso: "CV",
    label: "CAPE VERDE",
    nicename: "Cape Verde",
    iso3: "CPV",
    numcode: 132,
    phonecode: 238,
  },
  {
    value: 40,
    iso: "KY",
    label: "CAYMAN ISLANDS",
    nicename: "Cayman Islands",
    iso3: "CYM",
    numcode: 136,
    phonecode: 1345,
  },
  {
    value: 41,
    iso: "CF",
    label: "CENTRAL AFRICAN REPUBLIC",
    nicename: "Central African Republic",
    iso3: "CAF",
    numcode: 140,
    phonecode: 236,
  },
  {
    value: 42,
    iso: "TD",
    label: "CHAD",
    nicename: "Chad",
    iso3: "TCD",
    numcode: 148,
    phonecode: 235,
  },
  {
    value: 43,
    iso: "CL",
    label: "CHILE",
    nicename: "Chile",
    iso3: "CHL",
    numcode: 152,
    phonecode: 56,
  },
  {
    value: 44,
    iso: "CN",
    label: "CHINA",
    nicename: "China",
    iso3: "CHN",
    numcode: 156,
    phonecode: 86,
  },
  {
    value: 45,
    iso: "CX",
    label: "CHRISTMAS ISLAND",
    nicename: "Christmas Island",
    iso3: "CXR",
    numcode: 162,
    phonecode: 61,
  },
  {
    value: 46,
    iso: "CC",
    label: "COCOS (KEELING) ISLANDS",
    nicename: "Cocos (Keeling) Islands",
    iso3: null,
    numcode: null,
    phonecode: 672,
  },
  {
    value: 47,
    iso: "CO",
    label: "COLOMBIA",
    nicename: "Colombia",
    iso3: "COL",
    numcode: 170,
    phonecode: 57,
  },
  {
    value: 48,
    iso: "KM",
    label: "COMOROS",
    nicename: "Comoros",
    iso3: "COM",
    numcode: 174,
    phonecode: 269,
  },
  {
    value: 49,
    iso: "CG",
    label: "CONGO",
    nicename: "Congo",
    iso3: "COG",
    numcode: 178,
    phonecode: 242,
  },
  {
    value: 50,
    iso: "CD",
    label: "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
    nicename: "Congo, the Democratic Republic of the",
    iso3: "COD",
    numcode: 180,
    phonecode: 242,
  },
  {
    value: 51,
    iso: "CK",
    label: "COOK ISLANDS",
    nicename: "Cook Islands",
    iso3: "COK",
    numcode: 184,
    phonecode: 682,
  },
  {
    value: 52,
    iso: "CR",
    label: "COSTA RICA",
    nicename: "Costa Rica",
    iso3: "CRI",
    numcode: 188,
    phonecode: 506,
  },
  {
    value: 53,
    iso: "CI",
    label: "COTE D'IVOIRE",
    nicename: "Cote D'Ivoire",
    iso3: "CIV",
    numcode: 384,
    phonecode: 225,
  },
  {
    value: 54,
    iso: "HR",
    label: "CROATIA",
    nicename: "Croatia",
    iso3: "HRV",
    numcode: 191,
    phonecode: 385,
  },
  {
    value: 55,
    iso: "CU",
    label: "CUBA",
    nicename: "Cuba",
    iso3: "CUB",
    numcode: 192,
    phonecode: 53,
  },
  {
    value: 56,
    iso: "CY",
    label: "CYPRUS",
    nicename: "Cyprus",
    iso3: "CYP",
    numcode: 196,
    phonecode: 357,
  },
  {
    value: 57,
    iso: "CZ",
    label: "CZECHIA",
    nicename: "Czech Republic",
    iso3: "CZE",
    numcode: 203,
    phonecode: 420,
  },
  {
    value: 58,
    iso: "DK",
    label: "DENMARK",
    nicename: "Denmark",
    iso3: "DNK",
    numcode: 208,
    phonecode: 45,
  },
  {
    value: 59,
    iso: "DJ",
    label: "DJIBOUTI",
    nicename: "Djibouti",
    iso3: "DJI",
    numcode: 262,
    phonecode: 253,
  },
  {
    value: 60,
    iso: "DM",
    label: "DOMINICA",
    nicename: "Dominica",
    iso3: "DMA",
    numcode: 212,
    phonecode: 1767,
  },
  {
    value: 61,
    iso: "DO",
    label: "DOMINICAN REPUBLIC",
    nicename: "Dominican Republic",
    iso3: "DOM",
    numcode: 214,
    phonecode: 1,
  },
  {
    value: 62,
    iso: "EC",
    label: "ECUADOR",
    nicename: "Ecuador",
    iso3: "ECU",
    numcode: 218,
    phonecode: 593,
  },
  {
    value: 63,
    iso: "EG",
    label: "EGYPT",
    nicename: "Egypt",
    iso3: "EGY",
    numcode: 818,
    phonecode: 20,
  },
  {
    value: 64,
    iso: "SV",
    label: "EL SALVADOR",
    nicename: "El Salvador",
    iso3: "SLV",
    numcode: 222,
    phonecode: 503,
  },
  {
    value: 65,
    iso: "GQ",
    label: "EQUATORIAL GUINEA",
    nicename: "Equatorial Guinea",
    iso3: "GNQ",
    numcode: 226,
    phonecode: 240,
  },
  {
    value: 66,
    iso: "ER",
    label: "ERITREA",
    nicename: "Eritrea",
    iso3: "ERI",
    numcode: 232,
    phonecode: 291,
  },
  {
    value: 67,
    iso: "EE",
    label: "ESTONIA",
    nicename: "Estonia",
    iso3: "EST",
    numcode: 233,
    phonecode: 372,
  },
  {
    value: 68,
    iso: "ET",
    label: "ETHIOPIA",
    nicename: "Ethiopia",
    iso3: "ETH",
    numcode: 231,
    phonecode: 251,
  },
  {
    value: 69,
    iso: "FK",
    label: "FALKLAND ISLANDS (MALVINAS)",
    nicename: "Falkland Islands (Malvinas)",
    iso3: "FLK",
    numcode: 238,
    phonecode: 500,
  },
  {
    value: 70,
    iso: "FO",
    label: "FAROE ISLANDS",
    nicename: "Faroe Islands",
    iso3: "FRO",
    numcode: 234,
    phonecode: 298,
  },
  {
    value: 71,
    iso: "FJ",
    label: "FIJI",
    nicename: "Fiji",
    iso3: "FJI",
    numcode: 242,
    phonecode: 679,
  },
  {
    value: 72,
    iso: "FI",
    label: "FINLAND",
    nicename: "Finland",
    iso3: "FIN",
    numcode: 246,
    phonecode: 358,
  },
  {
    value: 73,
    iso: "FR",
    label: "FRANCE",
    nicename: "France",
    iso3: "FRA",
    numcode: 250,
    phonecode: 33,
  },
  {
    value: 74,
    iso: "GF",
    label: "FRENCH GUIANA",
    nicename: "French Guiana",
    iso3: "GUF",
    numcode: 254,
    phonecode: 594,
  },
  {
    value: 75,
    iso: "PF",
    label: "FRENCH POLYNESIA",
    nicename: "French Polynesia",
    iso3: "PYF",
    numcode: 258,
    phonecode: 689,
  },
  {
    value: 76,
    iso: "TF",
    label: "FRENCH SOUTHERN TERRITORIES",
    nicename: "French Southern Territories",
    iso3: "ATF",
    numcode: 260,
    phonecode: 0,
  },
  {
    value: 77,
    iso: "GA",
    label: "GABON",
    nicename: "Gabon",
    iso3: "GAB",
    numcode: 266,
    phonecode: 241,
  },
  {
    value: 78,
    iso: "GM",
    label: "GAMBIA",
    nicename: "Gambia",
    iso3: "GMB",
    numcode: 270,
    phonecode: 220,
  },
  {
    value: 79,
    iso: "GE",
    label: "GEORGIA",
    nicename: "Georgia",
    iso3: "GEO",
    numcode: 268,
    phonecode: 995,
  },
  {
    value: 80,
    iso: "DE",
    label: "GERMANY",
    nicename: "Germany",
    iso3: "DEU",
    numcode: 276,
    phonecode: 49,
  },
  {
    value: 81,
    iso: "GH",
    label: "GHANA",
    nicename: "Ghana",
    iso3: "GHA",
    numcode: 288,
    phonecode: 233,
  },
  {
    value: 82,
    iso: "GI",
    label: "GIBRALTAR",
    nicename: "Gibraltar",
    iso3: "GIB",
    numcode: 292,
    phonecode: 350,
  },
  {
    value: 83,
    iso: "GR",
    label: "GREECE",
    nicename: "Greece",
    iso3: "GRC",
    numcode: 300,
    phonecode: 30,
  },
  {
    value: 84,
    iso: "GL",
    label: "GREENLAND",
    nicename: "Greenland",
    iso3: "GRL",
    numcode: 304,
    phonecode: 299,
  },
  {
    value: 85,
    iso: "GD",
    label: "GRENADA",
    nicename: "Grenada",
    iso3: "GRD",
    numcode: 308,
    phonecode: 1473,
  },
  {
    value: 86,
    iso: "GP",
    label: "GUADELOUPE",
    nicename: "Guadeloupe",
    iso3: "GLP",
    numcode: 312,
    phonecode: 590,
  },
  {
    value: 87,
    iso: "GU",
    label: "GUAM",
    nicename: "Guam",
    iso3: "GUM",
    numcode: 316,
    phonecode: 1671,
  },
  {
    value: 88,
    iso: "GT",
    label: "GUATEMALA",
    nicename: "Guatemala",
    iso3: "GTM",
    numcode: 320,
    phonecode: 502,
  },
  {
    value: 89,
    iso: "GN",
    label: "GUINEA",
    nicename: "Guinea",
    iso3: "GIN",
    numcode: 324,
    phonecode: 224,
  },
  {
    value: 90,
    iso: "GW",
    label: "GUINEA-BISSAU",
    nicename: "Guinea-Bissau",
    iso3: "GNB",
    numcode: 624,
    phonecode: 245,
  },
  {
    value: 91,
    iso: "GY",
    label: "GUYANA",
    nicename: "Guyana",
    iso3: "GUY",
    numcode: 328,
    phonecode: 592,
  },
  {
    value: 92,
    iso: "HT",
    label: "HAITI",
    nicename: "Haiti",
    iso3: "HTI",
    numcode: 332,
    phonecode: 509,
  },
  {
    value: 93,
    iso: "HM",
    label: "HEARD ISLAND AND MCDONALD ISLANDS",
    nicename: "Heard Island and Mcdonald Islands",
    iso3: "HMD",
    numcode: 334,
    phonecode: 0,
  },
  {
    value: 94,
    iso: "VA",
    label: "HOLY SEE (VATICAN CITY STATE)",
    nicename: "Holy See (Vatican City State)",
    iso3: "VAT",
    numcode: 336,
    phonecode: 39,
  },
  {
    value: 95,
    iso: "HN",
    label: "HONDURAS",
    nicename: "Honduras",
    iso3: "HND",
    numcode: 340,
    phonecode: 504,
  },
  {
    value: 96,
    iso: "HK",
    label: "HONG KONG",
    nicename: "Hong Kong",
    iso3: "HKG",
    numcode: 344,
    phonecode: 852,
  },
  {
    value: 97,
    iso: "HU",
    label: "HUNGARY",
    nicename: "Hungary",
    iso3: "HUN",
    numcode: 348,
    phonecode: 36,
  },
  {
    value: 98,
    iso: "IS",
    label: "ICELAND",
    nicename: "Iceland",
    iso3: "ISL",
    numcode: 352,
    phonecode: 354,
  },
  {
    value: 99,
    iso: "IN",
    label: "INDIA",
    nicename: "India",
    iso3: "IND",
    numcode: 356,
    phonecode: 91,
  },
  {
    value: 100,
    iso: "ID",
    label: "INDONESIA",
    nicename: "Indonesia",
    iso3: "IDN",
    numcode: 360,
    phonecode: 62,
  },
  {
    value: 101,
    iso: "IR",
    label: "IRAN, ISLAMIC REPUBLIC OF",
    nicename: "Iran, Islamic Republic of",
    iso3: "IRN",
    numcode: 364,
    phonecode: 98,
  },
  {
    value: 102,
    iso: "IQ",
    label: "IRAQ",
    nicename: "Iraq",
    iso3: "IRQ",
    numcode: 368,
    phonecode: 964,
  },
  {
    value: 103,
    iso: "IE",
    label: "IRELAND",
    nicename: "Ireland",
    iso3: "IRL",
    numcode: 372,
    phonecode: 353,
  },
  {
    value: 104,
    iso: "IL",
    label: "ISRAEL",
    nicename: "Israel",
    iso3: "ISR",
    numcode: 376,
    phonecode: 972,
  },
  {
    value: 105,
    iso: "IT",
    label: "ITALY",
    nicename: "Italy",
    iso3: "ITA",
    numcode: 380,
    phonecode: 39,
  },
  {
    value: 106,
    iso: "JM",
    label: "JAMAICA",
    nicename: "Jamaica",
    iso3: "JAM",
    numcode: 388,
    phonecode: 1876,
  },
  {
    value: 107,
    iso: "JP",
    label: "JAPAN",
    nicename: "Japan",
    iso3: "JPN",
    numcode: 392,
    phonecode: 81,
  },
  {
    value: 108,
    iso: "JO",
    label: "JORDAN",
    nicename: "Jordan",
    iso3: "JOR",
    numcode: 400,
    phonecode: 962,
  },
  {
    value: 109,
    iso: "KZ",
    label: "KAZAKHSTAN",
    nicename: "Kazakhstan",
    iso3: "KAZ",
    numcode: 398,
    phonecode: 7,
  },
  {
    value: 110,
    iso: "KE",
    label: "KENYA",
    nicename: "Kenya",
    iso3: "KEN",
    numcode: 404,
    phonecode: 254,
  },
  {
    value: 111,
    iso: "KI",
    label: "KIRIBATI",
    nicename: "Kiribati",
    iso3: "KIR",
    numcode: 296,
    phonecode: 686,
  },
  {
    value: 112,
    iso: "KP",
    label: "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    nicename: "Korea, Democratic People's Republic of",
    iso3: "PRK",
    numcode: 408,
    phonecode: 850,
  },
  {
    value: 113,
    iso: "KR",
    label: "KOREA, REPUBLIC OF",
    nicename: "Korea, Republic of",
    iso3: "KOR",
    numcode: 410,
    phonecode: 82,
  },
  {
    value: 114,
    iso: "KW",
    label: "KUWAIT",
    nicename: "Kuwait",
    iso3: "KWT",
    numcode: 414,
    phonecode: 965,
  },
  {
    value: 115,
    iso: "KG",
    label: "KYRGYZSTAN",
    nicename: "Kyrgyzstan",
    iso3: "KGZ",
    numcode: 417,
    phonecode: 996,
  },
  {
    value: 116,
    iso: "LA",
    label: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    nicename: "Lao People's Democratic Republic",
    iso3: "LAO",
    numcode: 418,
    phonecode: 856,
  },
  {
    value: 117,
    iso: "LV",
    label: "LATVIA",
    nicename: "Latvia",
    iso3: "LVA",
    numcode: 428,
    phonecode: 371,
  },
  {
    value: 118,
    iso: "LB",
    label: "LEBANON",
    nicename: "Lebanon",
    iso3: "LBN",
    numcode: 422,
    phonecode: 961,
  },
  {
    value: 119,
    iso: "LS",
    label: "LESOTHO",
    nicename: "Lesotho",
    iso3: "LSO",
    numcode: 426,
    phonecode: 266,
  },
  {
    value: 120,
    iso: "LR",
    label: "LIBERIA",
    nicename: "Liberia",
    iso3: "LBR",
    numcode: 430,
    phonecode: 231,
  },
  {
    value: 121,
    iso: "LY",
    label: "LIBYAN ARAB JAMAHIRIYA",
    nicename: "Libyan Arab Jamahiriya",
    iso3: "LBY",
    numcode: 434,
    phonecode: 218,
  },
  {
    value: 122,
    iso: "LI",
    label: "LIECHTENSTEIN",
    nicename: "Liechtenstein",
    iso3: "LIE",
    numcode: 438,
    phonecode: 423,
  },
  {
    value: 123,
    iso: "LT",
    label: "LITHUANIA",
    nicename: "Lithuania",
    iso3: "LTU",
    numcode: 440,
    phonecode: 370,
  },
  {
    value: 124,
    iso: "LU",
    label: "LUXEMBOURG",
    nicename: "Luxembourg",
    iso3: "LUX",
    numcode: 442,
    phonecode: 352,
  },
  {
    value: 125,
    iso: "MO",
    label: "MACAO",
    nicename: "Macao",
    iso3: "MAC",
    numcode: 446,
    phonecode: 853,
  },
  {
    value: 126,
    iso: "MK",
    label: "NORTH MACEDONIA",
    nicename: "North Macedonia",
    iso3: "MKD",
    numcode: 807,
    phonecode: 389,
  },
  {
    value: 127,
    iso: "MG",
    label: "MADAGASCAR",
    nicename: "Madagascar",
    iso3: "MDG",
    numcode: 450,
    phonecode: 261,
  },
  {
    value: 128,
    iso: "MW",
    label: "MALAWI",
    nicename: "Malawi",
    iso3: "MWI",
    numcode: 454,
    phonecode: 265,
  },
  {
    value: 129,
    iso: "MY",
    label: "MALAYSIA",
    nicename: "Malaysia",
    iso3: "MYS",
    numcode: 458,
    phonecode: 60,
  },
  {
    value: 130,
    iso: "MV",
    label: "MALDIVES",
    nicename: "Maldives",
    iso3: "MDV",
    numcode: 462,
    phonecode: 960,
  },
  {
    value: 131,
    iso: "ML",
    label: "MALI",
    nicename: "Mali",
    iso3: "MLI",
    numcode: 466,
    phonecode: 223,
  },
  {
    value: 132,
    iso: "MT",
    label: "MALTA",
    nicename: "Malta",
    iso3: "MLT",
    numcode: 470,
    phonecode: 356,
  },
  {
    value: 133,
    iso: "MH",
    label: "MARSHALL ISLANDS",
    nicename: "Marshall Islands",
    iso3: "MHL",
    numcode: 584,
    phonecode: 692,
  },
  {
    value: 134,
    iso: "MQ",
    label: "MARTINIQUE",
    nicename: "Martinique",
    iso3: "MTQ",
    numcode: 474,
    phonecode: 596,
  },
  {
    value: 135,
    iso: "MR",
    label: "MAURITANIA",
    nicename: "Mauritania",
    iso3: "MRT",
    numcode: 478,
    phonecode: 222,
  },
  {
    value: 136,
    iso: "MU",
    label: "MAURITIUS",
    nicename: "Mauritius",
    iso3: "MUS",
    numcode: 480,
    phonecode: 230,
  },
  {
    value: 137,
    iso: "YT",
    label: "MAYOTTE",
    nicename: "Mayotte",
    iso3: "MYT",
    numcode: 175,
    phonecode: 269,
  },
  {
    value: 138,
    iso: "MX",
    label: "MEXICO",
    nicename: "Mexico",
    iso3: "MEX",
    numcode: 484,
    phonecode: 52,
  },
  {
    value: 139,
    iso: "FM",
    label: "MICRONESIA, FEDERATED STATES OF",
    nicename: "Micronesia, Federated States of",
    iso3: "FSM",
    numcode: 583,
    phonecode: 691,
  },
  {
    value: 140,
    iso: "MD",
    label: "MOLDOVA, REPUBLIC OF",
    nicename: "Moldova, Republic of",
    iso3: "MDA",
    numcode: 498,
    phonecode: 373,
  },
  {
    value: 141,
    iso: "MC",
    label: "MONACO",
    nicename: "Monaco",
    iso3: "MCO",
    numcode: 492,
    phonecode: 377,
  },
  {
    value: 142,
    iso: "MN",
    label: "MONGOLIA",
    nicename: "Mongolia",
    iso3: "MNG",
    numcode: 496,
    phonecode: 976,
  },
  {
    value: 143,
    iso: "MS",
    label: "MONTSERRAT",
    nicename: "Montserrat",
    iso3: "MSR",
    numcode: 500,
    phonecode: 1664,
  },
  {
    value: 144,
    iso: "MA",
    label: "MOROCCO",
    nicename: "Morocco",
    iso3: "MAR",
    numcode: 504,
    phonecode: 212,
  },
  {
    value: 145,
    iso: "MZ",
    label: "MOZAMBIQUE",
    nicename: "Mozambique",
    iso3: "MOZ",
    numcode: 508,
    phonecode: 258,
  },
  {
    value: 146,
    iso: "MM",
    label: "MYANMAR",
    nicename: "Myanmar",
    iso3: "MMR",
    numcode: 104,
    phonecode: 95,
  },
  {
    value: 147,
    iso: "NA",
    label: "NAMIBIA",
    nicename: "Namibia",
    iso3: "NAM",
    numcode: 516,
    phonecode: 264,
  },
  {
    value: 148,
    iso: "NR",
    label: "NAURU",
    nicename: "Nauru",
    iso3: "NRU",
    numcode: 520,
    phonecode: 674,
  },
  {
    value: 149,
    iso: "NP",
    label: "NEPAL",
    nicename: "Nepal",
    iso3: "NPL",
    numcode: 524,
    phonecode: 977,
  },
  {
    value: 150,
    iso: "NL",
    label: "NETHERLANDS",
    nicename: "Netherlands",
    iso3: "NLD",
    numcode: 528,
    phonecode: 31,
  },
  {
    value: 151,
    iso: "AN",
    label: "NETHERLANDS ANTILLES",
    nicename: "Netherlands Antilles",
    iso3: "ANT",
    numcode: 530,
    phonecode: 599,
  },
  {
    value: 152,
    iso: "NC",
    label: "NEW CALEDONIA",
    nicename: "New Caledonia",
    iso3: "NCL",
    numcode: 540,
    phonecode: 687,
  },
  {
    value: 153,
    iso: "NZ",
    label: "NEW ZEALAND",
    nicename: "New Zealand",
    iso3: "NZL",
    numcode: 554,
    phonecode: 64,
  },
  {
    value: 154,
    iso: "NI",
    label: "NICARAGUA",
    nicename: "Nicaragua",
    iso3: "NIC",
    numcode: 558,
    phonecode: 505,
  },
  {
    value: 155,
    iso: "NE",
    label: "NIGER",
    nicename: "Niger",
    iso3: "NER",
    numcode: 562,
    phonecode: 227,
  },
  {
    value: 156,
    iso: "NG",
    label: "NIGERIA",
    nicename: "Nigeria",
    iso3: "NGA",
    numcode: 566,
    phonecode: 234,
  },
  {
    value: 157,
    iso: "NU",
    label: "NIUE",
    nicename: "Niue",
    iso3: "NIU",
    numcode: 570,
    phonecode: 683,
  },
  {
    value: 158,
    iso: "NF",
    label: "NORFOLK ISLAND",
    nicename: "Norfolk Island",
    iso3: "NFK",
    numcode: 574,
    phonecode: 672,
  },
  {
    value: 159,
    iso: "MP",
    label: "NORTHERN MARIANA ISLANDS",
    nicename: "Northern Mariana Islands",
    iso3: "MNP",
    numcode: 580,
    phonecode: 1670,
  },
  {
    value: 160,
    iso: "NO",
    label: "NORWAY",
    nicename: "Norway",
    iso3: "NOR",
    numcode: 578,
    phonecode: 47,
  },
  {
    value: 161,
    iso: "OM",
    label: "OMAN",
    nicename: "Oman",
    iso3: "OMN",
    numcode: 512,
    phonecode: 968,
  },
  {
    value: 162,
    iso: "PK",
    label: "PAKISTAN",
    nicename: "Pakistan",
    iso3: "PAK",
    numcode: 586,
    phonecode: 92,
  },
  {
    value: 163,
    iso: "PW",
    label: "PALAU",
    nicename: "Palau",
    iso3: "PLW",
    numcode: 585,
    phonecode: 680,
  },
  {
    value: 164,
    iso: "PS",
    label: "PALESTINIAN TERRITORY, OCCUPIED",
    nicename: "Palestinian Territory, Occupied",
    iso3: null,
    numcode: null,
    phonecode: 970,
  },
  {
    value: 165,
    iso: "PA",
    label: "PANAMA",
    nicename: "Panama",
    iso3: "PAN",
    numcode: 591,
    phonecode: 507,
  },
  {
    value: 166,
    iso: "PG",
    label: "PAPUA NEW GUINEA",
    nicename: "Papua New Guinea",
    iso3: "PNG",
    numcode: 598,
    phonecode: 675,
  },
  {
    value: 167,
    iso: "PY",
    label: "PARAGUAY",
    nicename: "Paraguay",
    iso3: "PRY",
    numcode: 600,
    phonecode: 595,
  },
  {
    value: 168,
    iso: "PE",
    label: "PERU",
    nicename: "Peru",
    iso3: "PER",
    numcode: 604,
    phonecode: 51,
  },
  {
    value: 169,
    iso: "PH",
    label: "PHILIPPINES",
    nicename: "Philippines",
    iso3: "PHL",
    numcode: 608,
    phonecode: 63,
  },
  {
    value: 170,
    iso: "PN",
    label: "PITCAIRN",
    nicename: "Pitcairn",
    iso3: "PCN",
    numcode: 612,
    phonecode: 0,
  },
  {
    value: 171,
    iso: "PL",
    label: "POLAND",
    nicename: "Poland",
    iso3: "POL",
    numcode: 616,
    phonecode: 48,
  },
  {
    value: 172,
    iso: "PT",
    label: "PORTUGAL",
    nicename: "Portugal",
    iso3: "PRT",
    numcode: 620,
    phonecode: 351,
  },
  {
    value: 173,
    iso: "PR",
    label: "PUERTO RICO",
    nicename: "Puerto Rico",
    iso3: "PRI",
    numcode: 630,
    phonecode: 1787,
  },
  {
    value: 174,
    iso: "QA",
    label: "QATAR",
    nicename: "Qatar",
    iso3: "QAT",
    numcode: 634,
    phonecode: 974,
  },
  {
    value: 175,
    iso: "RE",
    label: "REUNION",
    nicename: "Reunion",
    iso3: "REU",
    numcode: 638,
    phonecode: 262,
  },
  {
    value: 176,
    iso: "RO",
    label: "ROMANIA",
    nicename: "Romania",
    iso3: "ROU",
    numcode: 642,
    phonecode: 40,
  },
  {
    value: 177,
    iso: "RU",
    label: "RUSSIAN FEDERATION",
    nicename: "Russian Federation",
    iso3: "RUS",
    numcode: 643,
    phonecode: 7,
  },
  {
    value: 178,
    iso: "RW",
    label: "RWANDA",
    nicename: "Rwanda",
    iso3: "RWA",
    numcode: 646,
    phonecode: 250,
  },
  {
    value: 179,
    iso: "SH",
    label: "SAINT HELENA",
    nicename: "Saint Helena",
    iso3: "SHN",
    numcode: 654,
    phonecode: 290,
  },
  {
    value: 180,
    iso: "KN",
    label: "SAINT KITTS AND NEVIS",
    nicename: "Saint Kitts and Nevis",
    iso3: "KNA",
    numcode: 659,
    phonecode: 1869,
  },
  {
    value: 181,
    iso: "LC",
    label: "SAINT LUCIA",
    nicename: "Saint Lucia",
    iso3: "LCA",
    numcode: 662,
    phonecode: 1758,
  },
  {
    value: 182,
    iso: "PM",
    label: "SAINT PIERRE AND MIQUELON",
    nicename: "Saint Pierre and Miquelon",
    iso3: "SPM",
    numcode: 666,
    phonecode: 508,
  },
  {
    value: 183,
    iso: "VC",
    label: "SAINT VINCENT AND THE GRENADINES",
    nicename: "Saint Vincent and the Grenadines",
    iso3: "VCT",
    numcode: 670,
    phonecode: 1784,
  },
  {
    value: 184,
    iso: "WS",
    label: "SAMOA",
    nicename: "Samoa",
    iso3: "WSM",
    numcode: 882,
    phonecode: 684,
  },
  {
    value: 185,
    iso: "SM",
    label: "SAN MARINO",
    nicename: "San Marino",
    iso3: "SMR",
    numcode: 674,
    phonecode: 378,
  },
  {
    value: 186,
    iso: "ST",
    label: "SAO TOME AND PRINCIPE",
    nicename: "Sao Tome and Principe",
    iso3: "STP",
    numcode: 678,
    phonecode: 239,
  },
  {
    value: 187,
    iso: "SA",
    label: "SAUDI ARABIA",
    nicename: "Saudi Arabia",
    iso3: "SAU",
    numcode: 682,
    phonecode: 966,
  },
  {
    value: 188,
    iso: "SN",
    label: "SENEGAL",
    nicename: "Senegal",
    iso3: "SEN",
    numcode: 686,
    phonecode: 221,
  },
  {
    value: 189,
    iso: "RS",
    label: "SERBIA",
    nicename: "Serbia",
    iso3: "SRB",
    numcode: 688,
    phonecode: 381,
  },
  {
    value: 190,
    iso: "SC",
    label: "SEYCHELLES",
    nicename: "Seychelles",
    iso3: "SYC",
    numcode: 690,
    phonecode: 248,
  },
  {
    value: 191,
    iso: "SL",
    label: "SIERRA LEONE",
    nicename: "Sierra Leone",
    iso3: "SLE",
    numcode: 694,
    phonecode: 232,
  },
  {
    value: 192,
    iso: "SG",
    label: "SINGAPORE",
    nicename: "Singapore",
    iso3: "SGP",
    numcode: 702,
    phonecode: 65,
  },
  {
    value: 193,
    iso: "SK",
    label: "SLOVAKIA",
    nicename: "Slovakia",
    iso3: "SVK",
    numcode: 703,
    phonecode: 421,
  },
  {
    value: 194,
    iso: "SI",
    label: "SLOVENIA",
    nicename: "Slovenia",
    iso3: "SVN",
    numcode: 705,
    phonecode: 386,
  },
  {
    value: 195,
    iso: "SB",
    label: "SOLOMON ISLANDS",
    nicename: "Solomon Islands",
    iso3: "SLB",
    numcode: 90,
    phonecode: 677,
  },
  {
    value: 196,
    iso: "SO",
    label: "SOMALIA",
    nicename: "Somalia",
    iso3: "SOM",
    numcode: 706,
    phonecode: 252,
  },
  {
    value: 197,
    iso: "ZA",
    label: "SOUTH AFRICA",
    nicename: "South Africa",
    iso3: "ZAF",
    numcode: 710,
    phonecode: 27,
  },
  {
    value: 198,
    iso: "GS",
    label: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    nicename: "South Georgia and the South Sandwich Islands",
    iso3: "SGS",
    numcode: 239,
    phonecode: 0,
  },
  {
    value: 199,
    iso: "ES",
    label: "SPAIN",
    nicename: "Spain",
    iso3: "ESP",
    numcode: 724,
    phonecode: 34,
  },
  {
    value: 200,
    iso: "LK",
    label: "SRI LANKA",
    nicename: "Sri Lanka",
    iso3: "LKA",
    numcode: 144,
    phonecode: 94,
  },
  {
    value: 201,
    iso: "SD",
    label: "SUDAN",
    nicename: "Sudan",
    iso3: "SDN",
    numcode: 736,
    phonecode: 249,
  },
  {
    value: 202,
    iso: "SR",
    label: "SURINAME",
    nicename: "Suriname",
    iso3: "SUR",
    numcode: 740,
    phonecode: 597,
  },
  {
    value: 203,
    iso: "SJ",
    label: "SVALBARD AND JAN MAYEN",
    nicename: "Svalbard and Jan Mayen",
    iso3: "SJM",
    numcode: 744,
    phonecode: 47,
  },
  {
    value: 204,
    iso: "SZ",
    label: "SWAZILAND",
    nicename: "Swaziland",
    iso3: "SWZ",
    numcode: 748,
    phonecode: 268,
  },
  {
    value: 205,
    iso: "SE",
    label: "SWEDEN",
    nicename: "Sweden",
    iso3: "SWE",
    numcode: 752,
    phonecode: 46,
  },
  {
    value: 206,
    iso: "CH",
    label: "SWITZERLAND",
    nicename: "Switzerland",
    iso3: "CHE",
    numcode: 756,
    phonecode: 41,
  },
  {
    value: 207,
    iso: "SY",
    label: "SYRIAN ARAB REPUBLIC",
    nicename: "Syrian Arab Republic",
    iso3: "SYR",
    numcode: 760,
    phonecode: 963,
  },
  {
    value: 208,
    iso: "TW",
    label: "TAIWAN, PROVINCE OF CHINA",
    nicename: "Taiwan, Province of China",
    iso3: "TWN",
    numcode: 158,
    phonecode: 886,
  },
  {
    value: 209,
    iso: "TJ",
    label: "TAJIKISTAN",
    nicename: "Tajikistan",
    iso3: "TJK",
    numcode: 762,
    phonecode: 992,
  },
  {
    value: 210,
    iso: "TZ",
    label: "TANZANIA, UNITED REPUBLIC OF",
    nicename: "Tanzania, United Republic of",
    iso3: "TZA",
    numcode: 834,
    phonecode: 255,
  },
  {
    value: 211,
    iso: "TH",
    label: "THAILAND",
    nicename: "Thailand",
    iso3: "THA",
    numcode: 764,
    phonecode: 66,
  },
  {
    value: 212,
    iso: "TL",
    label: "TIMOR-LESTE",
    nicename: "Timor-Leste",
    iso3: "TLS",
    numcode: 626,
    phonecode: 670,
  },
  {
    value: 213,
    iso: "TG",
    label: "TOGO",
    nicename: "Togo",
    iso3: "TGO",
    numcode: 768,
    phonecode: 228,
  },
  {
    value: 214,
    iso: "TK",
    label: "TOKELAU",
    nicename: "Tokelau",
    iso3: "TKL",
    numcode: 772,
    phonecode: 690,
  },
  {
    value: 215,
    iso: "TO",
    label: "TONGA",
    nicename: "Tonga",
    iso3: "TON",
    numcode: 776,
    phonecode: 676,
  },
  {
    value: 216,
    iso: "TT",
    label: "TRINIDAD AND TOBAGO",
    nicename: "Trinidad and Tobago",
    iso3: "TTO",
    numcode: 780,
    phonecode: 1868,
  },
  {
    value: 217,
    iso: "TN",
    label: "TUNISIA",
    nicename: "Tunisia",
    iso3: "TUN",
    numcode: 788,
    phonecode: 216,
  },
  {
    value: 218,
    iso: "TR",
    label: "TURKEY",
    nicename: "Turkey",
    iso3: "TUR",
    numcode: 792,
    phonecode: 90,
  },
  {
    value: 219,
    iso: "TM",
    label: "TURKMENISTAN",
    nicename: "Turkmenistan",
    iso3: "TKM",
    numcode: 795,
    phonecode: 993,
  },
  {
    value: 220,
    iso: "TC",
    label: "TURKS AND CAICOS ISLANDS",
    nicename: "Turks and Caicos Islands",
    iso3: "TCA",
    numcode: 796,
    phonecode: 1649,
  },
  {
    value: 221,
    iso: "TV",
    label: "TUVALU",
    nicename: "Tuvalu",
    iso3: "TUV",
    numcode: 798,
    phonecode: 688,
  },
  {
    value: 222,
    iso: "UG",
    label: "UGANDA",
    nicename: "Uganda",
    iso3: "UGA",
    numcode: 800,
    phonecode: 256,
  },
  {
    value: 223,
    iso: "UA",
    label: "UKRAINE",
    nicename: "Ukraine",
    iso3: "UKR",
    numcode: 804,
    phonecode: 380,
  },
  {
    value: 224,
    iso: "AE",
    label: "UNITED ARAB EMIRATES",
    nicename: "United Arab Emirates",
    iso3: "ARE",
    numcode: 784,
    phonecode: 971,
  },
  {
    value: 225,
    iso: "GB",
    label: "UNITED KINGDOM",
    nicename: "United Kingdom",
    iso3: "GBR",
    numcode: 826,
    phonecode: 44,
  },
  {
    value: 226,
    iso: "US",
    label: "UNITED STATES",
    nicename: "United States",
    iso3: "USA",
    numcode: 840,
    phonecode: 1,
  },
  {
    value: 227,
    iso: "UM",
    label: "UNITED STATES MINOR OUTLYING ISLANDS",
    nicename: "United States Minor Outlying Islands",
    iso3: "UMI",
    numcode: 581,
    phonecode: 1,
  },
  {
    value: 228,
    iso: "UY",
    label: "URUGUAY",
    nicename: "Uruguay",
    iso3: "URY",
    numcode: 858,
    phonecode: 598,
  },
  {
    value: 229,
    iso: "UZ",
    label: "UZBEKISTAN",
    nicename: "Uzbekistan",
    iso3: "UZB",
    numcode: 860,
    phonecode: 998,
  },
  {
    value: 230,
    iso: "VU",
    label: "VANUATU",
    nicename: "Vanuatu",
    iso3: "VUT",
    numcode: 548,
    phonecode: 678,
  },
  {
    value: 231,
    iso: "VE",
    label: "VENEZUELA",
    nicename: "Venezuela",
    iso3: "VEN",
    numcode: 862,
    phonecode: 58,
  },
  {
    value: 232,
    iso: "VN",
    label: "VIET NAM",
    nicename: "Viet Nam",
    iso3: "VNM",
    numcode: 704,
    phonecode: 84,
  },
  {
    value: 233,
    iso: "VG",
    label: "VIRGIN ISLANDS, BRITISH",
    nicename: "Virgin Islands, British",
    iso3: "VGB",
    numcode: 92,
    phonecode: 1284,
  },
  {
    value: 234,
    iso: "VI",
    label: "VIRGIN ISLANDS, U.S.",
    nicename: "Virgin Islands, U.s.",
    iso3: "VIR",
    numcode: 850,
    phonecode: 1340,
  },
  {
    value: 235,
    iso: "WF",
    label: "WALLIS AND FUTUNA",
    nicename: "Wallis and Futuna",
    iso3: "WLF",
    numcode: 876,
    phonecode: 681,
  },
  {
    value: 236,
    iso: "EH",
    label: "WESTERN SAHARA",
    nicename: "Western Sahara",
    iso3: "ESH",
    numcode: 732,
    phonecode: 212,
  },
  {
    value: 237,
    iso: "YE",
    label: "YEMEN",
    nicename: "Yemen",
    iso3: "YEM",
    numcode: 887,
    phonecode: 967,
  },
  {
    value: 238,
    iso: "ZM",
    label: "ZAMBIA",
    nicename: "Zambia",
    iso3: "ZMB",
    numcode: 894,
    phonecode: 260,
  },
  {
    value: 239,
    iso: "ZW",
    label: "ZIMBABWE",
    nicename: "Zimbabwe",
    iso3: "ZWE",
    numcode: 716,
    phonecode: 263,
  },
  {
    value: 240,
    iso: "ME",
    label: "MONTENEGRO",
    nicename: "Montenegro",
    iso3: "MNE",
    numcode: 499,
    phonecode: 382,
  },
  {
    value: 241,
    iso: "XK",
    label: "KOSOVO",
    nicename: "Kosovo",
    iso3: "XKX",
    numcode: 0,
    phonecode: 383,
  },
  {
    value: 242,
    iso: "AX",
    label: "ALAND ISLANDS",
    nicename: "Aland Islands",
    iso3: "ALA",
    numcode: 248,
    phonecode: 358,
  },
  {
    value: 243,
    iso: "BQ",
    label: "BONAIRE, SINT EUSTATIUS AND SABA",
    nicename: "Bonaire, Sint Eustatius and Saba",
    iso3: "BES",
    numcode: 535,
    phonecode: 599,
  },
  {
    value: 244,
    iso: "CW",
    label: "CURACAO",
    nicename: "Curacao",
    iso3: "CUW",
    numcode: 531,
    phonecode: 599,
  },
  {
    value: 245,
    iso: "GG",
    label: "GUERNSEY",
    nicename: "Guernsey",
    iso3: "GGY",
    numcode: 831,
    phonecode: 44,
  },
  {
    value: 246,
    iso: "IM",
    label: "ISLE OF MAN",
    nicename: "Isle of Man",
    iso3: "IMN",
    numcode: 833,
    phonecode: 44,
  },
  {
    value: 247,
    iso: "JE",
    label: "JERSEY",
    nicename: "Jersey",
    iso3: "JEY",
    numcode: 832,
    phonecode: 44,
  },
  {
    value: 248,
    iso: "BL",
    label: "SAINT BARTHELEMY",
    nicename: "Saint Barthelemy",
    iso3: "BLM",
    numcode: 652,
    phonecode: 590,
  },
  {
    value: 249,
    iso: "MF",
    label: "SAINT MARTIN",
    nicename: "Saint Martin",
    iso3: "MAF",
    numcode: 663,
    phonecode: 590,
  },
  {
    value: 250,
    iso: "SX",
    label: "SINT MAARTEN",
    nicename: "Sint Maarten",
    iso3: "SXM",
    numcode: 534,
    phonecode: 1,
  },
  {
    value: 251,
    iso: "SS",
    label: "SOUTH SUDAN",
    nicename: "South Sudan",
    iso3: "SSD",
    numcode: 728,
    phonecode: 211,
  },
];
