import React, { useContext } from "react";

import { NavLink } from "react-router-dom";

import { AccountConsumer } from "../context/AccountProvider";

export default function Navigation() {
  let acc = useContext(AccountConsumer);
  return acc.user_id ? (
    <header>
      <div className="nav-container">
        <div>
          <h1 className="tool-heading">Rocklode</h1>
        </div>
        <div className="nav-left">
          <span className="nav-item">
            <NavLink to="/resources" className={(navData) => (navData.isActive ? "nav-active" : undefined)}>
              Resources
            </NavLink>
          </span>
          <span className="nav-item">
            <NavLink to="/documents" className={(navData) => (navData.isActive ? "nav-active" : undefined)}>
              Documents
            </NavLink>
          </span>
          <span className="nav-item">
            <NavLink to="/projects" className={(navData) => (navData.isActive ? "nav-active" : undefined)}>
              Projects
            </NavLink>
          </span>
          <span className="nav-item">
            <NavLink to="/studys/new" className={(navData) => (navData.isActive ? "nav-active" : undefined)}>
              Studys
            </NavLink>
          </span>
          <span className="nav-item">
            <NavLink to="/companies" className={(navData) => (navData.isActive ? "nav-active" : undefined)}>
              Companies
            </NavLink>
          </span>
        </div>
        <div className="nav-right">
          <span className="nav-item">
            <NavLink to="/logout" className={(navData) => (navData.isActive ? "nav-active" : undefined)}>
              Logout
            </NavLink>
          </span>
        </div>
      </div>
    </header>
  ) : null;
}
