import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import API from "../../utils/API";

import { NavLink } from "react-router-dom";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Cell,
  ResponsiveContainer,
  ScatterChart,
  ZAxis,
  Scatter,
  LabelList,
} from "recharts";
import { ComposableMap, Geographies, Geography, Marker } from "react-simple-maps";

const geoUrl = "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries-sans-antarctica.json";

const markers = [
  {
    markerOffset: -15,
    name: "Buenos Aires",
    coordinates: [-58.3816, -34.6037],
  },
  { markerOffset: -15, name: "La Paz", coordinates: [-68.1193, -16.4897] },
  { markerOffset: 25, name: "Brasilia", coordinates: [-47.8825, -15.7942] },
  { markerOffset: 25, name: "Santiago", coordinates: [-70.6693, -33.4489] },
  { markerOffset: 25, name: "Bogota", coordinates: [-74.0721, 4.711] },
  { markerOffset: 25, name: "Quito", coordinates: [-78.4678, -0.1807] },
  { markerOffset: -15, name: "Georgetown", coordinates: [-58.1551, 6.8013] },
  { markerOffset: -15, name: "Asuncion", coordinates: [-57.5759, -25.2637] },
  { markerOffset: 25, name: "Paramaribo", coordinates: [-55.2038, 5.852] },
  { markerOffset: 25, name: "Montevideo", coordinates: [-56.1645, -34.9011] },
  { markerOffset: -15, name: "Caracas", coordinates: [-66.9036, 10.4806] },
  { markerOffset: -15, name: "Lima", coordinates: [-77.0428, -12.0464] },
];

function Projects() {
  const [resource_documents, set_resource_documents] = useState();
  const [project_chart_data, set_project_chart_data] = useState();
  const [document_count, set_document_count] = useState(null);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    GetResourceDocuments();
  }, [pageNumber]);

  async function GetResourceDocuments() {
    API.get("/projects", { params: { page: pageNumber, limit: 1000 } })
      .then((r) => {
        console.log(r.data.data);
        set_resource_documents(r.data.data);
        setNumPages(r.data.pages);
        set_document_count(r.data.results);

        let cd = r.data.data
          .map((cdd) => ({
            name: cdd.name,
            amt: (cdd.study.npv_usd / cdd.study.initial_capital_usd).toFixed(2),
            pt: cdd.study.tax_applied,
          }))
          .sort((a, b) => b.amt - a.amt);

        let cdd = r.data.data
          .map((cdd) => ({
            name: cdd.name,
            cap_eff: (cdd.study.npv_usd / cdd.study.initial_capital_usd).toFixed(2),
            npv: cdd.study.npv_usd,
            irr: cdd.study.irr,
            pt: cdd.study.tax_applied,
          }))
          .sort((a, b) => b.npv - a.npv);

        let cdddd = r.data.data
          .map((cdd) => ({
            name: cdd.owner.name,
            cap_eff: (cdd.study.npv_usd / cdd.study.initial_capital_usd).toFixed(2),
            mc_npv: cdd.owner.market_cap / 1000000 / cdd.study.npv_usd,
          }))
          .sort((a, b) => b.mc_npv - a.mc_npv);

        let map = r.data.data.map((cdd) => ({
          name: cdd.name,
          markerOffset: 10,
          coordinates: [cdd.longitude, cdd.latitude],
        }));

        set_project_chart_data({ cap_eff: cd, capp_eff_npv: cdd, npv_mc: cdddd, map: map });
      })
      .catch((e) => console.log(e));
  }

  return (
    <div className="main-content">
      <div className="page-nav">
        <h1>Projects</h1>
        <div className="page-nav-tabs">
          <nav>
            <span className="page-nav-item">Items</span>
          </nav>
          <div className="page-nav-actions">
            <div className="page-nav-actions-container">
              <NavLink to="new" className={(navData) => (navData.isActive ? "nav-active" : undefined)}>
                <button tabindex="0" class="primary-btn" type="button">
                  <svg focusable="false" height="16px" width="16px" viewBox="0 0 1024 1024">
                    <path
                      d="M448 128v319.936h-320v128h320v320.064h128v-320.064h319.936v-128h-319.936v-319.936z"
                      fill="currentColor"
                    ></path>
                  </svg>

                  <span>Create</span>
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <table className="item-list">
        <thead>
          <tr>
            <th>Name</th>
            <th>Owner</th>
            <th>Country</th>
            <th>Date</th>
            <th>Commodities</th>
            <th>Type</th>
            <th>Mine Life</th>
            <th>Type</th>
            <th>Mill (Mtpa)</th>
            <th>Mill ($/t pa)</th>
            <th>Mining ($/t)</th>
            <th>Proc ($/t)</th>
            <th>G&A ($/t)</th>
            <th>Capex</th>
            <th>NPV</th>
            <th>IRR</th>
            <th>Capital Efficiency</th>
            <th>NPV/MC</th>
          </tr>
        </thead>
        <tbody>
          {resource_documents
            ? resource_documents
                .sort((a, b) => new Date(b.study.effective_date) - new Date(a.study.effective_date))
                .map((item) => (
                  <tr key={item.id}>
                    <td>{item.name}</td>
                    <td>{item.owner.name}</td>
                    <td>{item.country_name}</td>
                    <td>{item.study && new Date(item.study.effective_date).toLocaleString().split(",")[0]}</td>
                    <td>
                      <div className="commodities_produced">
                        {item.study &&
                          item.study.commodities_produced &&
                          item.study.commodities_produced.map((cp) => (
                            <div key={cp.commodity} class="double-val-label">
                              <span className={`primary symbol-${cp.symbol}`}>{cp.symbol}</span>
                              {/* <span>
                                  {cp.quantity_pa}
                                  {cp.quantity_pa_unit_name} @ {cp.grade}
                                  {cp.grade_unit_name}
                                </span> */}
                            </div>
                          ))}
                      </div>
                    </td>
                    <td className="upper">{item.study && item.study.mine_type_short_name}</td>
                    <td>{item.study && item.study.mine_life} Years</td>
                    <td>{item.study && item.study.is_greenfield ? "" : "Brownfields"}</td>
                    <td>{item.study && ((item.study.mine_tons_per_day * 365) / 1000000).toFixed(1)}</td>
                    <td>
                      {item.study &&
                        Math.round(item.study.initial_capital_usd / ((item.study.mine_tons_per_day * 365) / 1000000))}
                    </td>
                    <td>{item.study && item.study.mining_cost_per_t_usd.toFixed(2)}</td>
                    <td>{item.study && item.study.processing_cost_per_t_usd.toFixed(2)}</td>
                    <td>{item.study && item.study.gna_cost_per_t_usd.toFixed(2)}</td>
                    <td>{item.study && Math.round(item.study.initial_capital_usd)}</td>
                    <td>{item.study && Math.round(item.study.npv_usd)}</td>
                    <td>{item.study && item.study.irr}</td>
                    <td>{parseFloat(item.study && item.study.npv_usd / item.study.initial_capital_usd).toFixed(2)}</td>
                    <td>
                      {item.study &&
                        Math.round(
                          (item.owner.market_cap / 1000000 / Math.round(item.study.npv_usd) + Number.EPSILON) * 100
                        ) / 100}
                    </td>
                  </tr>
                ))
            : null}
        </tbody>
      </table>

      {/* <div className="pagination-row">
            <span
              className={pageNumber == 0 ? "pg-disabled" : undefined}
              onClick={
                pageNumber == 0
                  ? undefined
                  : () => setPageNumber(pageNumber - 1)
              }
            >
              Previous Page
            </span>
            <span>
              Page {pageNumber} of {numPages}
            </span>
            <span
              className={pageNumber == numPages ? "pg-disabled" : undefined}
              onClick={
                pageNumber == numPages
                  ? undefined
                  : () => setPageNumber(pageNumber + 1)
              }
            >
              Next Page
            </span>
          </div> */}
      <br />
      <br />
      <div className="container-flex">
        <div>
          {project_chart_data &&
            project_chart_data.capp_eff_npv &&
            project_chart_data.capp_eff_npv &&
            project_chart_data.npv_mc && (
              <BarChart
                width={600}
                height={400}
                data={project_chart_data.cap_eff}
                margin={{
                  top: 0,
                  right: 30,
                  left: 20,
                  bottom: 100,
                }}
              >
                <text x={300} y={10} fill="black" textAnchor="middle" dominantBaseline="central">
                  <tspan fontSize="14">Capital efficiency (red=PRETAX)</tspan>
                </text>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" interval={0} tick={<CustomizedAxisTick />} />
                <YAxis />
                {/* <Bar dataKey="amt" fill="#8884d8" /> */}
                <Bar dataKey="amt">
                  {project_chart_data.cap_eff.map((entry, index) => (
                    <Cell key={index} fill={entry.pt === true ? "#F28C28" : "#FF4433"} />
                  ))}
                </Bar>
              </BarChart>
            )}
        </div>
        <div>
          {project_chart_data &&
            project_chart_data.capp_eff_npv &&
            project_chart_data.capp_eff_npv &&
            project_chart_data.npv_mc && (
              // <BarChart
              //   width={600}
              //   height={400}
              //   data={project_chart_data.capp_eff_npv}
              //   margin={{
              //     top: 0,
              //     right: 30,
              //     left: 20,
              //     bottom: 100,
              //   }}
              // >
              //   <text x={300} y={10} fill="black" textAnchor="middle" dominantBaseline="central">
              //     <tspan fontSize="14">NPV of January studys (red=PRETAX)</tspan>
              //   </text>
              //   <CartesianGrid strokeDasharray="3 3" />
              //   <XAxis dataKey="name" interval={0} tick={<CustomizedAxisTick />} />
              //   <YAxis />
              //   {/* <Bar dataKey="amt" fill="#8884d8" /> */}
              //   <Bar dataKey="npv">
              //     {project_chart_data.capp_eff_npv.map((entry, index) => (
              //       <Cell key={index} fill={entry.pt === true ? "#F28C28" : "#FF4433"} />
              //     ))}
              //   </Bar>
              // </BarChart>

              <ResponsiveContainer width={600} height={400}>
                <ScatterChart
                  margin={{
                    top: 0,
                    right: 30,
                    left: 20,
                    bottom: 100,
                  }}
                >
                  <text x={300} y={10} fill="black" textAnchor="middle" dominantBaseline="central">
                    <tspan fontSize="14">NPV & IRR (red=PRETAX)</tspan>
                  </text>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    type="number"
                    dataKey="npv"
                    name="npv"
                    unit="$m"
                    interval={0}
                    domain={[0, 100, 500, 1000, 2000]}
                    ticks={[0, 100, 500, 1000, 2000]}
                    scale="sqrt"
                  />
                  <YAxis type="number" dataKey="irr" name="irr" unit="%" />
                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />
                  <Scatter name="A school" data={project_chart_data.capp_eff_npv} fill="#F28C28">
                    <LabelList
                      dataKey="name"
                      // fill="#F28C28"
                      // position="bottom"
                      position="left"
                      // offset={(-10, -10)}
                      // StackOffsetType="sign"
                      // tickFormatter={(value) => value.toLocaleString().replace(/ /g, "\u00A0")}
                      fontSize={"75%"}
                      width={300}
                    />
                  </Scatter>
                </ScatterChart>
              </ResponsiveContainer>
            )}
        </div>
        <div>
          {project_chart_data &&
            project_chart_data.capp_eff_npv &&
            project_chart_data.capp_eff_npv &&
            project_chart_data.npv_mc && (
              <BarChart
                width={600}
                height={400}
                data={project_chart_data.npv_mc}
                margin={{
                  top: 0,
                  right: 30,
                  left: 20,
                  bottom: 100,
                }}
              >
                <text x={300} y={10} fill="black" textAnchor="middle" dominantBaseline="central">
                  <tspan fontSize="14">NPV / MC studys (red=PRETAX)</tspan>
                </text>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" interval={0} tick={<CustomizedAxisTick />} />
                <YAxis type="number" ticks={[0, 0.2, 0.4, 0.6, 0.8, 1.0]} scale="sqrt" />
                {/* <Bar dataKey="amt" fill="#8884d8" /> */}
                <Bar dataKey="mc_npv">
                  {project_chart_data.npv_mc.map((entry, index) => (
                    <Cell key={index} fill={"#F28C28"} />
                  ))}
                </Bar>
              </BarChart>
            )}
        </div>
      </div>

      <div>
        {project_chart_data && project_chart_data.map && (
          <ComposableMap
            // projection="geoAzimuthalEqualArea"
            width={1200}
            projectionConfig={
              {
                // rotate: [58, 20, 0],
                // scale: 400,
              }
            }
          >
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map((geo) => <Geography key={geo.rsmKey} geography={geo} fill="#EAEAEC" stroke="#D6D6DA" />)
              }
            </Geographies>
            {project_chart_data.map.map(({ name, coordinates, markerOffset }) => (
              <Marker key={name} coordinates={coordinates}>
                <circle r={3} fill="#F00" stroke="#fff" strokeWidth={1} />
                <text
                  textAnchor="middle"
                  y={markerOffset}
                  style={{ fontFamily: "system-ui", fill: "#5D5A6D", fontSize: "8px" }}
                >
                  {name}
                </text>
              </Marker>
            ))}
          </ComposableMap>
        )}
      </div>
    </div>
  );
}

const CustomizedLabel = (props) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
      {value}
    </text>
  );
};

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
        {payload.value}
      </text>
    </g>
  );
};

export default Projects;
