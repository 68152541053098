import "./css/app.scss";

import { Routes, Route } from "react-router-dom";
import ResourceForm from "./pages/resources/Form";
import Navigation from "./components/Navigation";

import AccountProvider from "./context/AccountProvider";
import FormLogin from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import StudyDocuments from "./pages/documents/studys";
import ResourceDocuments from "./pages/documents/resources";
import Home from "./pages/documents";
import FormProject from "./pages/projects/form";
import FormStudy from "./pages/studys/form";
import Projects from "./pages/projects/projects";
import TakeoverNewsDocuments from "./pages/documents/takeover_news";

function App() {
  return (
    <AccountProvider>
      <div className="main-container">
        <Navigation />
        <Routes>
          <Route path="/" element={<ResourceForm />} />

          <Route path="/login" element={<FormLogin />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<span>register</span>} />

          <Route path="resources">
            <Route path="new" element={<ResourceForm />} />
          </Route>

          <Route path="documents">
            <Route index={true} element={<Home />} />
            <Route path="study" element={<StudyDocuments />} />
            <Route path="resource" element={<ResourceDocuments />} />
            <Route path="takeover_news" element={<TakeoverNewsDocuments />} />
          </Route>

          <Route path="projects">
            <Route index={true} element={<Projects />} />
            <Route path="new" element={<FormProject />} />
          </Route>

          <Route path="studys">
            <Route path="new" element={<FormStudy />} />
          </Route>
        </Routes>
      </div>
    </AccountProvider>
  );
}

export default App;
