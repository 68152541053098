import { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AccountConsumer } from "../../context/AccountProvider";
import API from "../../utils/API";
import JWTDecode from "jwt-decode";

function FormLogin() {
  let navigate = useNavigate();
  const updateAccount = useContext(AccountConsumer);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = (data) => {
    API.post(`/login`, data)
      .then((r) => {
        localStorage.setItem("access_token", r.data.token);
        localStorage.setItem("refresh_token", r.data.refresh_token);
        console.log(JWTDecode(localStorage.getItem("access_token")));
        const { user_id, username, first_name } = JWTDecode(localStorage.getItem("access_token"));

        updateAccount.updateAccount({
          user_id: user_id,
          username: username,
          first_name: first_name,
        });
        navigate("/workorders");
      })
      .catch((e) => console.log(e.response.data.errors));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="login-form">
      <h1>WSCT</h1>
      <label htmlFor="username" className="bold">
        Username
      </label>
      <input type="text" {...register("username")} />
      {errors.username && <span>This field is required</span>}

      <label htmlFor="password" className="bold">
        Password
      </label>
      <input type="password" {...register("password")} />
      {errors.password && <span>This field is required</span>}

      <input type="submit" disabled={isSubmitting} value={"Login"} />
    </form>
  );
}

// const customStyles = {
//   option: (provided, state) => ({
//     ...provided,
//     // border: "1px dotted pink",
//     // color: state.isSelected ? "red" : "blue",
//     // padding: 10,
//   }),
//   control: () => ({
//     // none of react-select's styles are passed to <Control />
//     // width: 100,
//   }),
//   singleValue: (provided, state) => {
//     const opacity = state.isDisabled ? 0.5 : 1;
//     const transition = "opacity 300ms";

//     return { ...provided, opacity, transition };
//   },
// };

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "0.875rem",
    color: "rgb(1, 1, 1)",
    // borderBottom: "2px dotted green",
    // color: state.isSelected ? "yellow" : "black",
    // backgroundColor: state.isSelected ? "green" : "white",
  }),
  control: (provided) => ({
    ...provided,
    padding: "0",
    borderRadius: 0,
    colour: "rgb(1, 1, 1)",
    // textTransform: "capitalize",
    borderColor: "rgb(118, 118, 118);",
    minHeight: "0px",
    fontSize: "0.8rem",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    padding: "0",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: "0",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

export default FormLogin;
