import React from "react";
import { useFieldArray } from "react-hook-form";

export default ({ nestIndex, control, register, calculateFields }) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `resources[${nestIndex}].commodities`,
  });
  return (
    <>
      <table>
        <tbody>
          {fields.map((item, k) => {
            return (
              <tr key={item.id}>
                <td>
                  <select
                    {...register(`resources[${nestIndex}].commodities[${k}].commodity`, {
                      valueAsNumber: true,
                    })}
                    disabled={nestIndex > 0 && true}
                  >
                    <option value={1}>Silver (Ag)</option>
                    <option value={2}>Gold (Au)</option>
                    <option value={3}>Lead (Pb)</option>
                    <option value={4}>Zinc (Zn)</option>
                    <option value={5}>Copper (Cu)</option>
                    <option value={6}>Antimony (Sb)</option>
                    <option value={7}>Nickel (Ni)</option>
                    <option value={8}>Cobalt (Co)</option>
                    <option value={9}>Barium Sulfate (BaSO4)</option>
                    <option value={10}>Tin (Sn)</option>
                    <option value={11}>Molybdenum (Mo)</option>
                  </select>
                </td>
                <td>
                  <input
                    type="text"
                    {...register(`resources[${nestIndex}].commodities[${k}].grade`, {
                      valueAsNumber: true,
                      onChange: (e) => calculateFields(`resources[${nestIndex}]`, `commodities[${k}]`),
                    })}
                  />
                </td>
                <td>
                  {nestIndex === 0 ? (
                    <select
                      {...register(`resources[${nestIndex}].commodities[${k}].grade_unit`, {
                        valueAsNumber: true,
                        onChange: (e) => calculateFields(`resources[${nestIndex}]`, `commodities[${k}]`),
                      })}
                      disabled={nestIndex > 0 && true}
                    >
                      <option value={1}>%</option>
                      <option value={2}>g/t</option>
                      <option value={3}>ppm</option>
                    </select>
                  ) : (
                    <input
                      type="hidden"
                      {...register(`resources[${nestIndex}].commodities[${k}].grade_unit`, {
                        valueAsNumber: true,
                      })}
                    />
                  )}
                </td>

                <td>
                  <input type="hidden" name={`resources[${nestIndex}].commodities[${k}].contained`} {...register(`resources[${nestIndex}].commodities[${k}].contained`)} />

                  {/* {nestIndex === 0 && ( */}
                    <span className="fa-delete" type="button" onClick={() => remove(k)}>
                      Delete
                    </span>
                  {/* )} */}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {nestIndex === 0 && (
        <button
          type="button"
          className="cmd-btn"
          onClick={() =>
            append({
              commodity: 1,
              grade: undefined,
              grade_unit: 1,
            })
          }
        >
          Add Commodity
        </button>
      )}
    </>
  );
};
