import { useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { create, all } from "mathjs";
// import API from "../../utils/API";
import NestedArray from "./CommodityFieldArray";
import API from "../../utils/API";

function ResourceForm() {
  const math = create(all, {});

  const is_add_mode = true;
  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();

  const { fields, append, remove } = useFieldArray({
    name: "resources",
    control,
  });
  const last_res = watch("resources");
  const totalValues = watch("total");

  useEffect(() => {
    if (!is_add_mode) {
    } else {
      reset({
        name: "",
        resources: [
          {
            classification: 2,
            tonnes: undefined,
            tonnes_unit: 1,
            contained: undefined,
            contained_unit: 1,
            commodities: [],
          },
        ],
        total: {
          tonnes: undefined,
          tonnes_unit: 1,
          contained: undefined,
          contained_unit: 1,
          commodities: [],
        },
      });
    }
  }, [reset, is_add_mode]);

  const calculateWeightedTotal = () => {
    const c_Values = watch("resources");
    let total_tonnes = 0;
    let total_values = [];
    let all_commodities = [];
    let weighted_grades = [];
    let weighted_units = [];

    // (Grade * Contained Tons + Grade * Contained Tons) / Total Tonnes
    c_Values.forEach((r) => {
      total_tonnes = total_tonnes + r.contained;
      r.commodities.forEach((c) => {
        all_commodities.splice(0, 0, {
          commodity: c.commodity,
          grade: c.grade,
          grade_unit: c.grade_unit,
          contained: r.contained,
        });
      });
    });

    all_commodities.forEach((ca) => {
      if (weighted_grades[ca.commodity] !== undefined) {
        let this_c = ca.grade * ca.contained;
        weighted_grades[ca.commodity] = weighted_grades[ca.commodity] + this_c;
        weighted_units[ca.commodity] = ca.grade_unit;
      } else {
        weighted_grades[ca.commodity] = ca.grade * ca.contained;
        weighted_units[ca.commodity] = ca.grade_unit;
        // ABOVE COULDOF FUCKEDIT
      }
    });

    // // We need to get the unit for the final calculated contained

    weighted_grades.forEach((ta, i) => {
      let calulated_contained;
      if (weighted_units[i] === 1) {
        calulated_contained = math.round(total_tonnes * (ta / total_tonnes / 100));
      } else if (weighted_units[i] === 2) {
        calulated_contained = math.round(total_tonnes * (ta / total_tonnes / 1000000));
      } else if (weighted_units[i] === 3) {
        calulated_contained = math.round(total_tonnes * (ta / total_tonnes / 1000000));
      }
      total_values.push({
        commodity: i,
        grade: ta / total_tonnes,
        grade_unit: weighted_units[i],
        contained: calulated_contained,
      });
    });

    const total = {
      tonnes: total_tonnes,
      tonnes_unit: 1,
      contained: total_tonnes,
      contained_unit: 1,
      commodities: [...total_values],
    };
    return total;
  };

  const calculatFields = (ParentFieldName) => {
    const { tonnes, tonnes_unit, commodities } = getValues(ParentFieldName);
    let this_tonnes, this_tonnes_unit;

    if (tonnes_unit === 1) {
      this_tonnes_unit = "Mt";
    } else if (tonnes_unit === 2) {
      this_tonnes_unit = "kt";
    } else if (tonnes_unit === 3) {
      this_tonnes_unit = "t";
    }
    this_tonnes = math.unit(tonnes, this_tonnes_unit).toNumber("t");

    if (this_tonnes) {
      setValue(`${ParentFieldName}.contained`, math.unit(this_tonnes, "t").toNumber("g"));
    }

    commodities &&
      commodities.map((c, i) => {
        if (tonnes && c.grade) {
          let calulated_contained;

          if (c.grade_unit === 1) {
            //  %
            calulated_contained = math.round(this_tonnes * (c.grade / 100) * 1000 * 1000);
            console.log(tonnes, calulated_contained, c.commodity);
          } else if (c.grade_unit === 2) {
            // g/t
            calulated_contained = math.round(this_tonnes * c.grade);
          } else if (c.grade_unit === 3) {
            // ppm
            calulated_contained = math.round(this_tonnes * (c.grade / 1000000) * 1000 * 1000);
          }
          if (calulated_contained > 0) {
            // this is the calculated contained grams
            setValue(`${ParentFieldName}.commodities[${i}].contained`, calulated_contained);
          } else {
            setValue(`${ParentFieldName}.commodities[${i}].contained`, undefined);
          }
        }
      });
  };

  const onSubmit = (data) => {
    data.resources.push({
      classification: 1,
      ...data.total,
    });
    delete data.total;

    data.resources.map((rm) => {
      delete rm.tonnes;
      delete rm.tonnes_unit;
    });

    if (!is_add_mode) {
      //   API.patch(`/resources/${props.id}`, data)
      //     .then((r) => navigate(`/resources/${props.id}`))
      //     .catch((e) => console.log(e.response.data.errors));
    } else {
      console.log(data);
      API.post("/resources", data)
        // .then((r) => navigate(`/resources`))
        .catch((e) => console.log(e.response.data.errors));
    }
  };

  return (
    <div className="page-container">
      <div className="page-header">
        <div className="page-header-container">
          <div className="ph-left">
            <h2>Resource Form</h2>
          </div>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="standard-form">
        <input type="hidden" {...register("id", { valueAsNumber: true })} />

        <div className="flex-frm">
          <div className="form-column">
            <div className="form-flex-row-col">
              <label htmlFor="project" className="bold">
                Name
              </label>
              <input type="text" {...register("name")} />
            </div>

            {fields.map((item, i) => (
              <div key={item.id}>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <select
                          {...register(`resources[${i}].classification`, {
                            valueAsNumber: true,
                          })}
                        >
                          <option value={2}>Inferred</option>
                          <option value={3}>Indicated</option>
                          <option value={4}>Measured</option>
                          <option value={5}>Probable</option>
                          <option value={6}>Proven</option>
                        </select>
                      </td>
                      <td>
                        <input
                          type="text"
                          {...register(`resources[${i}].tonnes`, {
                            valueAsNumber: true,
                            onChange: (e) => calculatFields(`resources[${i}]`),
                          })}
                        />
                      </td>
                      <td>
                        <select
                          {...register(`resources[${i}].tonnes_unit`, {
                            valueAsNumber: true,
                            onChange: (e) => calculatFields(`resources[${i}]`),
                          })}
                        >
                          <option value={1}>Mt</option>
                          <option value={2}>kt</option>
                          <option value={3}>t</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <NestedArray calculateFields={calculatFields} nestIndex={i} {...{ control, register }} />
              </div>
            ))}
          </div>
        </div>

        {totalValues && totalValues.tonnes && (
          <table>
            <thead>
              <tr>
                <th> </th>
                <th colSpan={totalValues && totalValues.commodities.length}>Grade</th>
                <th colSpan={totalValues && totalValues.commodities.length}>Contained Metal</th>
              </tr>
              <tr>
                <th>Classification</th>
                <th>Mt</th>
                {totalValues &&
                  totalValues.commodities
                    .sort((a, b) => b.contained - a.contained)
                    .map((tv) => (
                      <th key={tv.contained}>
                        {ElementShortName(tv.commodity)} ({GradeUnitshort(tv.grade_unit)})
                      </th>
                    ))}
                {totalValues && totalValues.commodities.sort((a, b) => b.contained - a.contained).map((tv) => <th key={tv.contained}>{ElementShortName(tv.commodity)}</th>)}
              </tr>
            </thead>

            {/* <tbody>
              {last_res &&
                last_res
                  .sort((a, b) => b.classification - a.classification)
                  .map((r) => (
                    <tr>
                      <td>{r.classification}</td>
                      <td>{r.tonnes}</td>
                      {r.commodities
                        .sort((a, b) => b.contained - a.contained)
                        .map((tv) => (
                          <td>{tv.grade}</td>
                        ))}
                      {r.commodities
                        .sort((a, b) => b.contained - a.contained)
                        .map((tv) => (
                          <td>{math.round(math.unit(tv.contained, "g").toNumber("kt"), 0)}</td>
                        ))}
                    </tr>
                  ))}

              {totalValues && (
                <tr>
                  <td>Total</td>
                  <td>{totalValues.tonnes && math.unit(totalValues.tonnes, "g").toNumber("Mt")}</td>
                  {totalValues.commodities
                    .sort((a, b) => b.contained - a.contained)
                    .map((tv) => (
                      <td>{math.round(tv.grade, tv.grade_unit > 2 ? 0 : 2)}</td>
                    ))}
                  {totalValues.commodities
                    .sort((a, b) => b.contained - a.contained)
                    .map((tv) => (
                      <td>{math.round(math.unit(tv.contained, "g").toNumber("kt"), 0)}</td>
                    ))}
                </tr>
              )}
            </tbody> */}
          </table>
        )}

        <button
          type="button"
          className="cmd-btn"
          onClick={() =>
            append({
              classification: last_res[0].classification + 1,
              tonnes: undefined,
              tonnes_unit: last_res[0].tonnes_unit,
              contained: undefined,
              contained_unit: 1,
              commodities: [
                ...last_res[0].commodities.map((c) => ({
                  commodity: c.commodity,
                  grade: undefined,
                  grade_unit: c.grade_unit,
                })),
              ],
            })
          }
        >
          Add Classification
        </button>

        <button type="button" onClick={() => setValue("total", calculateWeightedTotal())}>
          Calculate Total
        </button>

        <input type="submit" disabled={isSubmitting} value={is_add_mode ? "Add Resource" : "Save Changes"} />
      </form>
    </div>
  );
}

function ElementShortName(value) {
  let element_short;
  if (value === 1) {
    element_short = "Ag";
  } else if (value === 2) {
    element_short = "Au";
  } else if (value === 3) {
    element_short = "Pb";
  } else if (value === 4) {
    element_short = "Zn";
  } else if (value === 5) {
    element_short = "Cu";
  } else if (value === 6) {
    element_short = "Sb";
  } else if (value === 7) {
    element_short = "Ni";
  } else if (value === 8) {
    element_short = "Co";
  } else if (value === 9) {
    element_short = "BaSO4";
  }
  return element_short || "N/A";
}

function GradeUnitshort(value) {
  let element_short;
  if (value === 1) {
    element_short = "%";
  } else if (value === 2) {
    element_short = "g/t";
  } else if (value === 3) {
    element_short = "ppm";
  }
  return element_short;
}

export default ResourceForm;
