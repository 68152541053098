import { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import API from "../../utils/API";

import { country_list } from "./countrys";

function FormProject() {
  const is_add_mode = true;
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  useEffect(() => {
    if (!is_add_mode) {
    } else {
      reset({
        name: "",
        latitude: undefined,
        longitude: undefined,
        country: undefined,
        owner: undefined,
      });
    }
  }, [reset, is_add_mode]);

  const onSubmit = (data) => {
    data.country = data.country.value;
    data.owner = data.owner.value;
    if (!is_add_mode) {
    } else {
      console.log(data);
      API.post("/projects", data).catch((e) => console.log(e.response.data.errors));
    }
  };

  const filterCompanys = (inputValue) =>
    API.get(`/get_select_options`, { params: { option: "COMPANY_LIST", search_string: inputValue } }).then((r) => {
      return r.data;
    });

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(filterCompanys(inputValue));
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register("id", { valueAsNumber: true })} />
      <table className="edit">
        <tbody>
          <tr>
            <th>Name:</th>
            <td className="full-content" colSpan="3">
              <input type="text" {...register("name")} />
            </td>
          </tr>
          <tr>
            <th>Country:</th>
            <td className="full-content" colSpan="3">
              <Controller
                name="country"
                rules={{ required: true }}
                control={control}
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={country_list}
                    onChange={onChange}
                    isMulti={false}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    ref={ref}
                  />
                )}
              />
            </td>
          </tr>
          <tr>
            <td>Latitude</td>
            <td>
              <input
                type="text"
                {...register("latitude", { setValueAs: (v) => ((v == "") | (v == 0) ? null : parseFloat(v)) })}
              />
            </td>
            <td>Longtitude</td>
            <td>
              <input
                type="text"
                {...register("longitude", { setValueAs: (v) => ((v == "") | (v == 0) ? null : parseFloat(v)) })}
              />
            </td>
          </tr>
          <tr>
            <th>Owner:</th>
            <td className="full-content" colSpan="3">
              <Controller
                name="owner"
                control={control}
                render={({ field }) => (
                  <AsyncSelect
                    {...field}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isClearable
                    cacheOptions
                    loadOptions={promiseOptions}
                  />
                )}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <input type="submit" disabled={isSubmitting} value={is_add_mode ? "Add Project" : "Save Changes"} />
    </form>
  );
}

export default FormProject;
