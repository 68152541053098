import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import API from "../../utils/API";

function ResourceDocuments() {
  const [resource_documents, set_resource_documents] = useState();
  const [document_count, set_document_count] = useState(null);
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    GetResourceDocuments();
  }, [pageNumber]);

  async function GetResourceDocuments() {
    API.get("/documents/resource", { params: { page: pageNumber, limit: 15 } })
      .then((r) => {
        set_resource_documents(r.data.data);
        setNumPages(r.data.pages);
        set_document_count(r.data.results);
      })
      .catch((e) => console.log(e));
  }

  return (
    <div className="page-container">
      <div className="page-header">
        <div className="page-header-container">
          <div className="ph-left">
            <h3>{document_count && document_count} resource_documents</h3>
          </div>
        </div>
      </div>

      <div className="column">
        <div className="container ">
          {/* <ProjectList resource_documents={resource_documents} /> */}
          <table className="primary-table">
            <thead>
              <tr>
                <th>id</th>
                <th>date</th>
                <th>url</th>
              </tr>
            </thead>
            <tbody>
              {resource_documents
                ? resource_documents.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <a href={item.file_url}>{item.id}</a>
                      </td>
                      <td>
                        <span className="bubble-span">{item.date && format(parseISO(item.date), "dd MMM Y")} </span>
                      </td>
                      <td>{item.file_url}</td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>

          <div className="pagination-row">
            <span className={pageNumber == 0 ? "pg-disabled" : undefined} onClick={pageNumber == 0 ? undefined : () => setPageNumber(pageNumber - 1)}>
              Previous Page
            </span>
            <span>
              Page {pageNumber} of {numPages}
            </span>
            <span className={pageNumber == numPages ? "pg-disabled" : undefined} onClick={pageNumber == numPages ? undefined : () => setPageNumber(pageNumber + 1)}>
              Next Page
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

{
}

export default ResourceDocuments;
